// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, Row, Col } from "antd";
import { fetchCategories, fetchBlogrollsCategories } from "~/redux/actions/app";
import { fetchPostList } from "~/redux/actions/post";

// views
import SiteLayout from "~/components/layout/SiteLayout";
import AppMobileNav from "~/components/app/AppMobileNav";
import CategoryNavs from "~/components/header/CategoryNavs";
import NewPostCard from "~/components/sider/NewPostCard";
import SiteBanner from "~/components/site/SiteBanner";
import SiderPrimary from "~/components/sider/SiderPrimary";
import AppSearch from "~/components/app/AppSearch";
import PostList from "~/components/post/PostList";

// utils
import { getCookie } from "~/utils/cookie";

const HomePage = (props) => {
  const { categories, postList, blogrolls } = props;

  return (
    <SiteLayout
      pageSider={
        <div>
          <NewPostCard />
          <SiderPrimary />
        </div>
      }
      blogrolls={blogrolls}>
      <Row>
        <Col xs={24} sm={24} md={0}>
          <AppMobileNav />
          <NewPostCard />
          <AppSearch />
        </Col>
      </Row>

      <SiteBanner />

      <Card>
        <CategoryNavs categories={categories} selected="all" />
      </Card>

      <PostList postList={postList} sort={props.sort} />
    </SiteLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  user: state.user.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

HomePage.requireUser = () => false;

HomePage.getInitialProps = async (ctx) => {
  const { query } = ctx;

  // 加载首页的所有版块
  const allCategories = await fetchCategories();
  const categories = allCategories.filter((x) => !x.paid);

  // 加载首页的帖子列表
  const cookieSort = getCookie("sort", ctx.req);
  const sort = query.sort || cookieSort;
  const page = parseInt(query.page) || 1;
  const postList = await fetchPostList({ sort, page });

  // 加载首页底部的友情链接
  let blogrolls = [];
  const blogrollCategories = await fetchBlogrollsCategories();
  if (blogrollCategories) {
    for (let index = 0; index < blogrollCategories.length; index++) {
      const element = blogrollCategories[index];
      if (element.name === "首页底部") {
        blogrolls = element.blogrolls;
        break;
      }
    }
  }

  return {
    postList,
    categories,
    sort,
    page,
    blogrolls,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
